<template>
  <div id="login">
    <div class="login-container">
      <div class="forms-container">
        <div class="signin-signup">
          <form action="" class="sign-in-form login-form">
            <h2 class="title">{{ $t("用户短信发送平台") }}</h2>
            <div class="input-field">
              <i class="fas fa-user"></i>
              <input
                type="text"
                :placeholder="$t('用户名')"
                v-model="loginFormData.username"
              />
            </div>
            <div class="input-field">
              <i class="fas fa-lock"></i>
              <input
                type="password"
                :placeholder="$t('密码')"
                id="id_password"
                v-model="loginFormData.password"
              />
              <i
                class="far fa-eye"
                id="togglePassword"
                style="cursor: pointer"
              ></i>
            </div>
            <div class="input-field">
              <i class="fas fa-key"></i>
              <input
                type="text"
                :placeholder="$t('验证码')"
                v-model="loginFormData.captcha"
              />
            </div>
            <div id="code">
              <img :src="picPath" alt="" @click="loginVerify" />
            </div>
            <!-- <a href="#" class="key use-keyboard-input">虚拟键盘</a> -->
            <!-- <a class="pass" href="#">忘记密码了吗？</a> -->
            <input
              type="submit"
              :value="$t('登录')"
              class="btn solid"
              @click.prevent="onSubmit"
              v-loading.fullscreen.lock="fullscreenLoading"
            />
            <!-- <p class="social-text">您可以使用以下方式登录：</p>
          <div class="social-media">
            <a href="#" class="social-icon" aria-label="Register with Google">
              <i class="fab fa-google"></i>
            </a>
            <a href="#" class="social-icon" aria-label="Register with Discord">
              <i class="fab fa-discord"></i>
            </a>
            <a href="#" class="social-icon" aria-label="Register with Twitter">
              <i class="fab fa-twitter"></i>
            </a>
            <a href="#" class="social-icon">
              <i class="fab fa-facebook-f" aria-label="Register with Facebook"></i>
            </a>
          </div>
          <div class="social-media">
            <a class="icon-mode" onclick="toggleTheme('dark');"><i class="fas fa-moon"></i></a>
            <a class="icon-mode" onclick="toggleTheme('light');"><i class="fas fa-sun"></i></a>
          </div>
          <p class="text-mode">{{$t('改变主题')}}</p> -->
          </form>
          <form action="" class="sign-up-form login-form">
            <h2 class="title">Register</h2>
            <div class="input-field">
              <i class="fas fa-user"></i>
              <input
                type="text"
                name="usuario"
                autocomplete="username"
                placeholder="Username"
                required="yes"
              />
            </div>
            <div class="input-field">
              <i class="fas fa-envelope"></i>
              <input
                type="email"
                name="correo"
                autocomplete="email"
                placeholder="Email"
                required="yes"
              />
            </div>
            <div class="input-field">
              <i class="fas fa-lock"></i>
              <input
                type="password"
                name="contraseña"
                autocomplete="current-password"
                placeholder="Password"
                id="id_reg"
                required="yes"
              />
              <i class="far fa-eye" id="toggleReg" style="cursor: pointer"></i>
            </div>
            <a href="#" class="key use-keyboard-input">Virtual keyboard</a>
            <a
              href="https://priva.reversecode.repl.co/tools/pass.html"
              class="pass"
              target="_blank"
              >Generate a strong password</a
            >
            <label class="check">
              <input type="checkbox" checked="checked" />
              <span class="checkmark"
                >I accept the <a href="terms.html">terms and services</a></span
              >
            </label>
            <input type="submit" value="Create account" class="btn solid" />
            <p class="social-text">You can register with:</p>
            <div class="social-media">
              <a href="#" class="social-icon" aria-label="Register with Google">
                <i class="fab fa-google"></i>
              </a>
              <a
                href="#"
                class="social-icon"
                aria-label="Register with Discord"
              >
                <i class="fab fa-discord"></i>
              </a>
              <a
                href="#"
                class="social-icon"
                aria-label="Register with Twitter"
              >
                <i class="fab fa-twitter"></i>
              </a>
              <a href="#" class="social-icon">
                <i
                  class="fab fa-facebook-f"
                  aria-label="Register with Facebook"
                ></i>
              </a>
            </div>
          </form>
        </div>
      </div>
      <div class="panels-container">
        <div class="panel left-panel">
          <div class="content">
            <!-- <h3>没有账号？</h3> -->
            <!-- <p>立即创建您的帐户立即创建您的帐户立即创建您的帐户立即创建您的帐户</p> -->
            <!-- <button class="btn transparent" id="sign-up-btn">注册</button> -->
          </div>
          <!-- <img src="img/log.svg" class="image" alt="" /> -->
        </div>

        <!-- <div class="panel right-panel">
          <div class="content">
            <h3>Already have an account?</h3>
            <p>Login to see your notifications and post your favorite photos</p>
            <button class="btn transparent" id="sign-in-btn">Sign in</button>
          </div>
          <img src="img/register.svg" class="image" alt="" />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
};
</script>

<script setup>
// import CryptoJS from "crypto-js";
import { reactive, ref, onBeforeMount, onMounted } from "vue";
import { captcha, login, getToken } from "@/http/api/login.js";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ElMessage, ElLoading } from "element-plus";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const router = useRouter();
const store = useStore();

const picPath = ref(null);
const loginFormData = reactive({
  username: "",
  password: "",
  captcha: "",
  captchaId: "",
});
// 获取验证码
const loginVerify = () => {
  captcha({}).then((ele) => {
    picPath.value = ele.data.picPath;
    loginFormData.captchaId = ele.data.captchaId;
  });
};

// 登录
const fullscreenLoading = ref(false); //loading
const onSubmit = async (values) => {
  const res = await login(loginFormData);
  if (res.code === 0) {
    fullscreenLoading.value = true;
    setTimeout(async () => {
      if (res.code === 0) {
        store.commit("setToken", res.data.token.access_token);
        store.commit("setUserInfo", {
          username: loginFormData.username,
          password: loginFormData.password,
          payType: res.data.payType,
        });
        fullscreenLoading.value = false;
        router.push({ path: "/dashboard" });
        // getToken().then((obj) => {
        //   store.commit("setToken", obj.data.access_token);
        // });
      } else {
        loginVerify();
      }
    }, 2000);
  } else {
    loginFormData.captcha = "";
    loginVerify();
  }
};

// onBeforeMount(async () => {
//   getToken().then((obj) => {
//     store.commit("setToken", obj.data.access_token);
//   });
// });
onMounted(async () => {
  // 切换密码状态
  const togglePassword = document.querySelector("#togglePassword");
  const password = document.querySelector("#id_password");

  togglePassword.addEventListener("click", function (e) {
    const type =
      password.getAttribute("type") === "password" ? "text" : "password";
    password.setAttribute("type", type);
    this.classList.toggle("fa-eye-slash");
  });

  loginVerify();
});
</script>

<style lang="scss" scoped>
@import "../../style/login/login.css";
</style>
